const assets = {
    "global": {

    },
    "intro": {
        0: {
            planeVideo: '/chapter1/video/1-1_planelanding_no_audio/HLS.m3u8',
            planeVideoPoster: '/images/intro/video/1-1_planelanding_2b.jpg',
            watercolor: '/images/intro/watercolor.png',
            titleImagine: '/images/intro/introTitleTextImagine.png',
            titleWould: '/images/intro/introTitleTextWould.png',
            titleWelcome: '/images/intro/introTitleTextWelcome.png',
            titleGoggles: '/images/intro/introTitleTextGoggles.png',
            watertower: '/images/intro/water-tower.png',
            richard: '/images/intro/richard.png',
            brothers: '/images/intro/brothers.png',
            band: '/images/intro/band.png',
            goBtn: '/images/intro/btn-go.png',
            treeA: "/images/intro/treeA.png",
            treeB: "/images/intro/treeB.png",
            flysound: "/audio/intro/0_a_fly_buzz.mp3",
            soundfile1: "/audio/intro/0_richard.mp3",
            soundfile2: "/audio/intro/0_trooper_loop.mp3"
        },
        1: {
            text1: '/images/intro/sub1/text-left-top.png',
            text2: '/images/intro/sub1/text-left-mid.png',
            text3: '/images/intro/sub1/text-right-top.png',
            text4: '/images/intro/sub1/text-right-bottom.png',
            planeLandingVideo: '/chapter1/video/1-1_planelanding_2_hi/HLS.m3u8',
            planeLandingEndLoopVideo: '/chapter1/video/1-1_planelanding_endloop_hi/HLS.m3u8',
            planeLandingVideoPoster: '/images/intro/video/1-1_planelanding_2.jpg',
            planeLandingEndLoopVideoPoster: '/images/intro/video/1-1_planelanding_endloop.jpg'
        },
        2: {
            roadVideo: '/chapter1/video/1-3_road_hi/HLS.m3u8',
            roadVideoPoster: '/images/intro/video/1-3_road.jpg',
            text1: '/images/intro/sub2/text-left.png',
            text2: '/images/intro/sub2/text-right.png',
            soundfile1: "/audio/chapter2/2_b_trucks.mp3"
        },
        3: {
            background: '/images/intro/wood-background.jpg',
            bookCover: '/images/intro/book-cover.png',
            bookCoverText: '/images/intro/book-cover-text.png',
            pageLeft: '/images/intro/pageLeft.png',
            pageRight: '/images/intro/pageRight.png',
            polaroidFrame: '/images/intro/book/ch2_s3_polaroidFrame.png',
            text0: '/images/intro/book/ch2_s2_title.png',
            text1: '/images/intro/book/ch2_s2_copy1.png',
            text2: '/images/intro/book/ch2_s2_copy2.png',
            text3: '/images/intro/book/ch2_s2_copy3.png',
            text4: '/images/intro/book/ch2_s3_copy1.png',
            text5: '/images/intro/book/ch2_s3_copy2.png',
            text6: '/images/intro/book/ch2_s3_copy3.png',
            text7: '/images/intro/book/ch2_s4_copy1.png',
            text8: '/images/intro/book/ch2_s4_copy2b.png',
            text9: '/images/intro/book/ch2_s4_copy3.png',
            trucks1: '/images/intro/book/sub1-trucks/trucks-1.jpg',
            trucks2: '/images/intro/book/sub1-trucks/trucks-2.jpg',
            trucks3: '/images/intro/book/sub1-trucks/trucks-3.jpg',
            trucks4: '/images/intro/book/sub1-trucks/trucks-4.jpg',
            trucks5: '/images/intro/book/sub1-trucks/trucks-5.jpg',
            trucks6: '/images/intro/book/sub1-trucks/trucks-6.jpg',
            trucks7: '/images/intro/book/sub1-trucks/trucks-7.jpg',
            p2slide1: '/images/intro/book/sub1-slide/ch2_s2_slide1.png',
            p2slide2: '/images/intro/book/sub1-slide/ch2_s2_slide2.png',
            p2slide3: '/images/intro/book/sub1-slide/ch2_s2_slide3.png',
            p2slide4: '/images/intro/book/sub1-slide/ch2_s2_slide4.png',
            p2slide5: '/images/intro/book/sub1-slide/ch2_s2_slide5.png',
            p2slide6: '/images/intro/book/sub1-slide/ch2_s2_slide6.png',
            p2slide7: '/images/intro/book/sub1-slide/ch2_s2_slide7.png',
            p2slide8: '/images/intro/book/sub1-slide/ch2_s2_slide8.png',
            p2slide9: '/images/intro/book/sub1-slide/ch2_s2_slide9.png',
            p2slide10: '/images/intro/book/sub1-slide/ch2_s2_slide10.png',
            p2slide11: '/images/intro/book/sub1-slide/ch2_s2_slide11.png',
            p2slide12: '/images/intro/book/sub1-slide/ch2_s2_slide12.png',
            p2slide13: '/images/intro/book/sub1-slide/ch2_s2_slide13.png',
            p2slide14: '/images/intro/book/sub1-slide/ch2_s2_slide14.png',
            p2slide15: '/images/intro/book/sub1-slide/ch2_s2_slide15.png',
            p2slide16: '/images/intro/book/sub1-slide/ch2_s2_slide16.png',
            polaroid1: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid1.jpg',
            polaroid2: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid2.jpg',
            polaroid3: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid3.jpg',
            polaroid4: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid4.jpg',
            polaroid5: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid5.jpg',
            polaroid6: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid6.jpg',
            polaroid7: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid7.jpg',
            polaroid8: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid8.jpg',
            polaroid9: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid9.jpg',
            polaroid10: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid10.jpg',
            polaroid11: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid11.jpg',
            polaroid12: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid12.jpg',
            polaroid13: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid13.jpg',
            polaroid14: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid14.jpg',
            polaroid15: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid15.jpg',
            polaroid16: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid16.jpg',
            polaroid17: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid17.jpg',
            polaroid18: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid18.jpg',
            polaroid19: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid19.jpg',
            polaroid20: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid20.jpg',
            polaroid21: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid21.jpg',
            polaroid22: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid22.jpg',
            polaroid23: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid23.jpg',
            polaroid24: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid24.jpg',
            polaroid25: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid25.jpg',
            polaroid26: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid26.jpg',
            polaroid27: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid27.jpg',
            polaroid28: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid28.jpg',
            polaroid29: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid29.jpg',
            polaroid30: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid30.jpg',
            polaroid31: '/images/intro/book/sub2/slides-3/ch2_s3_polaroid31.jpg',
            wheelie1: '/images/intro/book/sub2/slides-2/ch2_s3_wheelie1.png',
            wheelie2: '/images/intro/book/sub2/slides-2/ch2_s3_wheelie2.png',
            wheelie3: '/images/intro/book/sub2/slides-2/ch2_s3_wheelie3.png',
            wheelie4: '/images/intro/book/sub2/slides-2/ch2_s3_wheelie4.png',
            wheelie5: '/images/intro/book/sub2/slides-2/ch2_s3_wheelie5.png',
            wheelie6: '/images/intro/book/sub2/slides-2/ch2_s3_wheelie6.png',
            wheelie7: '/images/intro/book/sub2/slides-2/ch2_s3_wheelie7.png',
            wheelie8: '/images/intro/book/sub2/slides-2/ch2_s3_wheelie8.png',
            hair1: '/images/intro/book/sub2/slides-1/ch2_s3_hair1.jpg',
            hair2: '/images/intro/book/sub2/slides-1/ch2_s3_hair2.jpg',
            hair3: '/images/intro/book/sub2/slides-1/ch2_s3_hair3.jpg',
            hair4: '/images/intro/book/sub2/slides-1/ch2_s3_hair4.jpg',
            hair5: '/images/intro/book/sub2/slides-1/ch2_s3_hair5.jpg',
            hair6: '/images/intro/book/sub2/slides-1/ch2_s3_hair6.jpg',
            hair7: '/images/intro/book/sub2/slides-1/ch2_s3_hair7.jpg',
            hair8: '/images/intro/book/sub2/slides-1/ch2_s3_hair8.jpg',
            hair9: '/images/intro/book/sub2/slides-1/ch2_s3_hair9.jpg',
            hair10: '/images/intro/book/sub2/slides-1/ch2_s3_hair10.jpg',
            hair11: '/images/intro/book/sub2/slides-1/ch2_s3_hair11.jpg',
            hair12: '/images/intro/book/sub2/slides-1/ch2_s3_hair12.jpg',
            hair13: '/images/intro/book/sub2/slides-1/ch2_s3_hair13.jpg',
            hair14: '/images/intro/book/sub2/slides-1/ch2_s3_hair14.jpg',
            hair15: '/images/intro/book/sub2/slides-1/ch2_s3_hair15.jpg',
            hair16: '/images/intro/book/sub2/slides-1/ch2_s3_hair16.jpg',
            hair17: '/images/intro/book/sub2/slides-1/ch2_s3_hair17.jpg',
            hair18: '/images/intro/book/sub2/slides-1/ch2_s3_hair18.jpg',
            hair19: '/images/intro/book/sub2/slides-1/ch2_s3_hair19.jpg',
            hair20: '/images/intro/book/sub2/slides-1/ch2_s3_hair20.jpg',
            hair21: '/images/intro/book/sub2/slides-1/ch2_s3_hair21.jpg',
            panel1: '/images/intro/book/sub2/slides-4/ch2_s3_panel1.png',
            panel2: '/images/intro/book/sub2/slides-4/ch2_s3_panel2.png',
            panel3: '/images/intro/book/sub2/slides-4/ch2_s3_panel3.png',
            panel4: '/images/intro/book/sub2/slides-4/ch2_s3_panel4.png',
            panel5: '/images/intro/book/sub2/slides-4/ch2_s3_panel5.png',
            panel6: '/images/intro/book/sub2/slides-4/ch2_s3_panel6.png',
            panel7: '/images/intro/book/sub2/slides-4/ch2_s3_panel7.png',
            panel8: '/images/intro/book/sub2/slides-4/ch2_s3_panel8.png',
            panel9: '/images/intro/book/sub2/slides-4/ch2_s3_panel9.png',
            panel10: '/images/intro/book/sub2/slides-4/ch2_s3_panel10.png',
            panel11: '/images/intro/book/sub2/slides-4/ch2_s3_panel11.png',
            panel12: '/images/intro/book/sub2/slides-4/ch2_s3_panel12.png',
            panel13: '/images/intro/book/sub2/slides-4/ch2_s3_panel13.png',
            panel14: '/images/intro/book/sub2/slides-4/ch2_s3_panel14.png',
            panel15: '/images/intro/book/sub2/slides-4/ch2_s3_panel15.png',
            panel16: '/images/intro/book/sub2/slides-4/ch2_s3_panel16.png',
            panel17: '/images/intro/book/sub2/slides-4/ch2_s3_panel17.png',
            panel18: '/images/intro/book/sub2/slides-4/ch2_s3_panel18.png',
            tower1: '/images/intro/book/sub3/slide/ch2_s4_Tower1.png',
            tower2: '/images/intro/book/sub3/slide/ch2_s4_Tower2.png',
            tower3: '/images/intro/book/sub3/slide/ch2_s4_Tower3.png',
            tower4: '/images/intro/book/sub3/slide/ch2_s4_Tower4.png',
            tower5: '/images/intro/book/sub3/slide/ch2_s4_Tower5.png',
            icon1Front: '/images/intro/book/sub3/stash-sprite.png',
            icon1Back: '/images/intro/book/sub3/stash-text.png',
            icon2Front: '/images/intro/book/sub3/smoking-sprite.png',
            icon2Back: '/images/intro/book/sub3/smoking-text.png',
            icon3Front: '/images/intro/book/sub3/condom-1.png',
            icon3Back: '/images/intro/book/sub3/condom-text.png',
            icon4Front: '/images/intro/book/sub3/roach-sprite.png',
            icon4Back: '/images/intro/book/sub3/roach-text.png',
            icon5Front: '/images/intro/book/sub3/helmet-1.png',
            icon5Back: '/images/intro/book/sub3/helmet-text.png',
            icon6Front: '/images/intro/book/sub3/seat-sprite.png',
            icon6Back: '/images/intro/book/sub3/seat-text.png',
            icon7Front: '/images/intro/book/sub3/stubby-1.png',
            icon7Back: '/images/intro/book/sub3/stubby-text.png',
            icon8Front: '/images/intro/book/sub3/logo-sprite.png',
            icon8Back: '/images/intro/book/sub3/logo-text.png',
            icon9Front: '/images/intro/book/sub3/color-80s-1.png',
            icon9Back: '/images/intro/book/sub3/color-80s-text.png',
            icon10Front: '/images/intro/book/sub3/color-70s-1.png',
            icon10Back: '/images/intro/book/sub3/color-70s-text.png',
            iconFlipSound: "/audio/chapter2/2_tiles_flip.mp3",
            iconDropSound: "/audio/chapter2/2_tiles_drop.mp3",
            page1Sound: "/audio/chapter2/2_b_trucks.mp3",
            page2Sound: "/audio/chapter2/2_c_party.mp3",
            page3Sound: "/audio/chapter2/2_d_tiles.mp3",
            pageFlipSound: "/audio/chapter2/2_page_flip.mp3",
            soundfile1: "/audio/chapter2/2_z_basetrack.mp3"

        },
        4: {
            skaterVideo: '/chapter2/video/2-6_skater_loop_hi/HLS.m3u8',
            skaterVideoPoster: '/images/intro/video/2-6_skater_loop.jpg',
            text1: '/images/intro/ch2_s5_copy1.png',
            text2: '/images/intro/ch2_s5_copy2.png',
            caption: '/images/intro/caption-2-5.png',
            nextTitle: '/images/titles/TownTitle.jpg',
            soundfile1: "/audio/chapter2/2_z_basetrack.mp3"
        }
    },
    "town": {
        0: {
            title: '/images/titles/TownTitle.jpg',
            soundfile2: '/audio/chapter3/3_z_basetrack2.mp3',
        },
        1: {
            background: '/images/town/town-bg.jpg',
            townText: '/images/town/town-text.png',
            townMap: '/images/town/town-map.png',
            soundfile1: '/audio/chapter3/3_b_town.mp3',
            tree1: '/images/town/tree1.png',
            tree2: '/images/town/tree2.png',
            tree3: '/images/town/tree3.png',
            tree4: '/images/town/tree4.png',
            tree5: '/images/town/tree5.png',
            tree6: '/images/town/tree6.png',
            tree7: '/images/town/tree7.png',
            townItem1: '/images/town/bomb-assets/fire-hydro.png',
            townItem2: '/images/town/bomb-assets/lamp.png',
            townItem3: '/images/town/bomb-assets/parkinglot.png',
            townItem5: '/images/town/bomb-assets/power-lines.png',
            townItem6: '/images/town/bomb-assets/road.png',
            townItem7: '/images/town/bomb-assets/sewer.png',
            townItem8: '/images/town/bomb-assets/sidewalk.png',
            townItem9: '/images/town/bomb-assets/stop-sign.png',
            building1: '/images/town/bomb-assets/buildings/b1.png',
            building2: '/images/town/bomb-assets/buildings/b2.png',
            building3: '/images/town/bomb-assets/buildings/b3.png',
            building4: '/images/town/bomb-assets/buildings/b4.png',
            building5: '/images/town/bomb-assets/buildings/b5.png',
            building6: '/images/town/bomb-assets/buildings/b6.png',
            building7: '/images/town/bomb-assets/buildings/b7.png',
            building8: '/images/town/bomb-assets/buildings/b8.png',
            person1: '/images/town/bomb-assets/people/1.png',
            person2: '/images/town/bomb-assets/people/2.png',
            person3: '/images/town/bomb-assets/people/3.png',
            person4: '/images/town/bomb-assets/people/4.png',
            person5: '/images/town/bomb-assets/people/5.png',
            person6: '/images/town/bomb-assets/people/6.png',
            person7: '/images/town/bomb-assets/people/7.png',
            person8: '/images/town/bomb-assets/people/8.png',
        },
        2: {
            text1: '/images/town/Ch3Sub2Text1.png', 
            slide1: '/images/town/sub2_slideshow/ch3_bg6.jpg', 
            slide1Credit: "Credit: B. Winter/NWT Archives/G-1989-007-0003",
            slide2: '/images/town/sub2_slideshow/ch3_bg4.jpg',
            slide3: '/chapter3/video/sub2/3-3_aerial_loop_hi/HLS.m3u8',
            slide4: '/images/town/sub2_slideshow/ch3_bg1.jpg',
            slide4Credit: "Credit: Cominco/NWT Archives/N-1987-021-0055",
            soundfile1: "/audio/chapter3/3_c_aerial_loop.mp3"
        },
        3: {
            text1: '/images/town/3b-Yellowknife.png', 
            carOverlay: '/images/town/car-overlay.png', 
            tape1: '/images/town/ch5_tape1.png', 
            tape2: '/images/town/ch5_tape2.png', 
            tape3: '/images/town/ch5_tape3.png', 
            bgVideo: '/chapter5/videos/5-2_houses_loop_lo/HLS.m3u8',
            bgVideoPoster: '/images/town/5-2_houses_loop_poster.jpg',
            soundfile1: '/audio/chapter3/3_d_car.mp3',
        },
        4: {
            text1: '/images/town/Ch3Sub4Text1.png', 
            text2: '/images/town/Ch3Sub4Text2.png', 
            bgVideo: '/chapter5/videos/5-3_hill_hi/HLS.m3u8',
            bgVideoLoop: '/chapter5/videos/5-3_hill_loop_hi/HLS.m3u8',
            bgVideoPoster: '/images/town/5-3_hill_poster.jpg',
            soundfile2: '/audio/chapter3/3_z_basetrack2.mp3',
            soundfile1: '/audio/chapter3/3_e_backyard_loop.mp3',
            nextTitle: '/images/titles/PinepointersTitle.jpg',
        }
    },
    "pinepointers": {
        0: {
            title: '/images/titles/PinepointersTitle.jpg',
            soundfile2: '/audio/chapter4/4_a_loader.mp3',
        },
        1: {
            text1: '/images/pinepointers/sub1/text-1.png',
            background: '/images/pinepointers/sub1/background.jpg',
            soundfile1: '/audio/chapter4/4_z_basetrack1.mp3',
        },
        2: {
            text1: '/images/pinepointers/sub2/text-1.png',
            badge: '/images/pinepointers/sub2/badge.png',
            caption: '/images/pinepointers/sub2/caption-4-2.png',
            highlight: '/images/pinepointers/sub2/highlight.png',
            playVideoBtn: '/images/pinepointers/sub2/playVideo.png',
            background: '/images/pinepointers/sub2/background.png', 
            bgVideo: '/chapter4/videos/4-2_kim_concertloop_hi/HLS.m3u8',
            bgVideoPoster: '/images/pinepointers/sub2/videoPlaceHolder.jpg',
            musicVideo: '/chapter4/videos/4_kim_musicvid_hi/HLS.m3u8',
            soundfile2: '/audio/chapter4/4_vo_kim.mp3',
        },
        3: {
            text1: '/images/pinepointers/sub3/text-1.png',
            badge: '/images/pinepointers/sub3/badge.png',
            caption: '/images/pinepointers/sub3/caption-4-3.png',
            highlight1: '/images/pinepointers/sub3/highlight1.png',
            highlight2: '/images/pinepointers/sub3/highlight2.png',
            background: '/images/pinepointers/sub3/background.png', 
            soundfile2: '/audio/chapter4/4_vo_lw.mp3',
        },
        4: {
            text1: '/images/pinepointers/sub4/text-1.png',
            badge: '/images/pinepointers/sub4/badge.png',
            highlight: '/images/pinepointers/sub4/highlight.png',
            background: '/images/pinepointers/sub4/background.png', 
            creature1: '/images/pinepointers/sub4/creatures/c-1.png', 
            creature2: '/images/pinepointers/sub4/creatures/c-2.png', 
            creature3: '/images/pinepointers/sub4/creatures/c-3.png', 
            creature4: '/images/pinepointers/sub4/creatures/c-4.png', 
            creature5: '/images/pinepointers/sub4/creatures/c-5.png', 
            creature6: '/images/pinepointers/sub4/creatures/c-6.png', 
            creature7: '/images/pinepointers/sub4/creatures/c-7.png', 
            creature8: '/images/pinepointers/sub4/creatures/c-8.png', 
            creature9: '/images/pinepointers/sub4/creatures/c-9.png', 
            soundfile2: '/audio/chapter4/4_vo_richard.mp3',
        },
        5: {
            text1: '/images/pinepointers/sub5/text-1.png',
            text2: '/images/pinepointers/sub5/text-2.png',
            background: '/images/pinepointers/sub5/background.jpg', 
            soundfile1: '/audio/chapter4/4_z_basetrack2.mp3',
        },
        6: {
            text1: '/images/pinepointers/sub6/text.png',
            background: '/images/pinepointers/sub6/background.png', 
            badge: '/images/pinepointers/sub6/badge.png',
            badgeTop: '/images/pinepointers/sub6/badgeTop.png',
            bgVideo1: '/chapter4/videos/4-3_kim_4_hi/HLS.m3u8',
            bgVideo2: '/chapter4/videos/4-3_kim_5_hi/HLS.m3u8',
            bgVideo3: '/chapter4/videos/4-3_kim_1_hi/HLS.m3u8',
            bgVideoPoster1: '/images/pinepointers/sub6/vid1Preview.jpg',
            bgVideoPoster2: '/images/pinepointers/sub6/vid2Preview.jpg',
            bgVideoPoster3: '/images/pinepointers/sub6/vid3Preview.jpg',
            soundfile2: '/audio/chapter4/4_now_kim_loop.mp3',
        },
        7: {
            text1: '/images/pinepointers/sub7/text.png',
            background: '/images/pinepointers/sub7/background.png', 
            badge: '/images/pinepointers/sub7/badge.png',
            badgeTop: '/images/pinepointers/sub7/badgeTop.png',
            bgVideoPoster1: '/images/pinepointers/sub7/vidPreview1.jpg',
            bgVideoPoster2: '/images/pinepointers/sub7/vidPreview2.jpg',
            bgVideoPoster3: '/images/pinepointers/sub7/vidPreview3.jpg',
            bgVideo1: '/chapter4/videos/4-3_brothers_wayne_hi/HLS.m3u8',
            bgVideo2: '/chapter4/videos/4-3_brothers_lyle_hi/HLS.m3u8',
            bgVideo3: '/chapter4/videos/4_now_brothers_1_hi/HLS.m3u8',
            soundfile2: '/audio/chapter4/4_now_lw_loop.mp3',
        },
        8: {
            text1: '/images/pinepointers/sub8/text.png',
            background: '/images/pinepointers/sub8/background.png', 
            badge: '/images/pinepointers/sub8/badge.png',
            badgeTop: '/images/pinepointers/sub8/badgeTop.png',
            bgVideo: '/chapter4/videos/4-3_richard_1_hi/HLS.m3u8',
            bgVideoPoster: '/images/pinepointers/sub8/vidPreview.png',
            soundfile1: '/audio/chapter4/4_z_basetrack2.mp3',
            soundfile2: '/audio/chapter4/4_now_richard_loop.mp3',
            nextTitle: '/images/titles/EndsAndOddsTitle.jpg'
        }
    },
    "ends-and-odds": {
        0: {
            title: '/images/titles/EndsAndOddsTitle.jpg',
            soundfile1: '/audio/chapter6/5_z_basetrack.mp3',
            soundfile2: '/audio/chapter6/5_a_loader.mp3'
        },
        1: {
            text1: '/images/ends-and-odds/sub1/Ch5Sub1Text1.png',
            background: '/images/ends-and-odds/sub1/spoonHolderBg.png', 
            spoon: '/images/ends-and-odds/sub1/spoonSpriteTransparentBg2.png', 
            slide1: '/chapter6/videos/6-2_spoon_loop1_hi/HLS.m3u8',
            slide1Poster: '/images/ends-and-odds/sub1_slideshow/6-2_spoon_loop1_hi_poster.jpg',
            slide2: '/images/ends-and-odds/sub1_slideshow/spoonSlide1.jpg',
            slide3: '/images/ends-and-odds/sub1_slideshow/spoonSlide2.jpg',
            slide4: '/images/ends-and-odds/sub1_slideshow/spoonSlide3.jpg',
            slide5: '/images/ends-and-odds/sub1_slideshow/spoonSlide4.jpg',
            slide6: '/images/ends-and-odds/sub1_slideshow/spoonSlide5.jpg',
            slide7: '/images/ends-and-odds/sub1_slideshow/spoonSlide6.jpg',
            slide8: '/chapter6/videos/6-2_spoon_loop2_hi/HLS.m3u8',
            slide8Poster: '/images/ends-and-odds/sub1_slideshow/6-2_spoon_loop2_hi_poster.jpg',
            slide9: '/images/ends-and-odds/sub1_slideshow/spoonSlide7.jpg',
            slide10: '/images/ends-and-odds/sub1_slideshow/spoonSlide8.jpg',
            slide11: '/images/ends-and-odds/sub1_slideshow/spoonSlide9.jpg',
            slide12: '/images/ends-and-odds/sub1_slideshow/spoonSlide10.jpg',
            slide13: '/images/ends-and-odds/sub1_slideshow/spoonSlide11.jpg',
            slide14: '/images/ends-and-odds/sub1_slideshow/spoonSlide12.jpg',
            slide15: '/images/ends-and-odds/sub1_slideshow/spoonSlide13.jpg',
            slide16: '/images/ends-and-odds/sub1_slideshow/spoonSlide14.jpg',
            slide17: '/images/ends-and-odds/sub1_slideshow/spoonSlide15.jpg',
            slide18: '/images/ends-and-odds/sub1_slideshow/spoonSlide16.jpg',
        },
        2: {
            text1: '/images/ends-and-odds/sub2/Ch6Sub2Text1.png',
            text2: '/images/ends-and-odds/sub2/Ch6Sub2Text2.png',
            bgVideo: '/chapter6/videos/6-3_cominco_hi/HLS.m3u8',
            bgVideoPoster: '/images/ends-and-odds/sub2/bgVideoPoster.jpg',
        },
        3: {
            text1: '/images/ends-and-odds/sub3/tape_text.png',
            background: '/images/ends-and-odds/sub3/background.jpg',
            badge1: '/images/ends-and-odds/sub3_badges/elementary_pin.png',
            badge2: '/images/ends-and-odds/sub3_badges/figure_skating.png',
            badge3: '/images/ends-and-odds/sub3_badges/polar_bear.png',
            badge4: '/images/ends-and-odds/sub3_badges/arctic_winter_games.png',
            badge5: '/images/ends-and-odds/sub3_badges/polar_bear_school_pin.png',
            badge6: '/images/ends-and-odds/sub3_badges/pp_scan.png',
            badge7: '/images/ends-and-odds/sub3_badges/pp_scouts_flag.png',
            badge8: '/images/ends-and-odds/sub3_badges/pp_mine_badge.png',
            badge9: '/images/ends-and-odds/sub3_badges/water_tower_pin.png',
            badge10: '/images/ends-and-odds/sub3_badges/pp_mines_coin.png',
            badge11: '/images/ends-and-odds/sub3_badges/star_trucking.png',
            badge12: '/images/ends-and-odds/sub3_badges/mosquito_country.png',
            badge13: '/images/ends-and-odds/sub3_badges/first_pin.png',
            badge14: '/images/ends-and-odds/sub3_badges/fun_under_sun_crest.png',
            badge15: '/images/ends-and-odds/sub3_badges/pp_nwt_school_crest.png',
            badge16: '/images/ends-and-odds/sub3_badges/hockey_pin.png',
            badge17: '/images/ends-and-odds/sub3_badges/pp_scouts_patch.png',
            badge18: '/images/ends-and-odds/sub3_badges/turned_out_light_badge.png',
            badge19: '/images/ends-and-odds/sub3_badges/curling_pin.png',
            badge20: '/images/ends-and-odds/sub3_badges/pinepoint_law_badge.png',
            badge21: '/images/ends-and-odds/sub3_badges/town_pin.png',
            badge22: '/images/ends-and-odds/sub3_badges/george_patch.png',
            badge23: '/images/ends-and-odds/sub3_badges/pp_taxi.png',
            badge24: '/images/ends-and-odds/sub3_badges/pp_pillowcase.png',
            badge25: '/images/ends-and-odds/sub3_badges/fat_albert.png',
            badge26: '/images/ends-and-odds/sub3_badges/mosquito_trap.png',
            badge27: '/images/ends-and-odds/sub3_badges/fire_dept.png',
            badge28: '/images/ends-and-odds/sub3_badges/closure_crest.png',
            badge29: '/images/ends-and-odds/sub3_badges/badge_op.png',
            badge30: '/images/ends-and-odds/sub3_badges/matonabbee_school_pin.png',
            badge31: '/images/ends-and-odds/sub3_badges/75years.png',
            badge32: '/images/ends-and-odds/sub3_badges/pp_buckle.png',
            badge33: '/images/ends-and-odds/sub3_badges/fire_badge.png',
            badge34: '/images/ends-and-odds/sub3_badges/n_81_mines.png',
            badge35: '/images/ends-and-odds/sub3_badges/galena_heights_pin.png',
            badge36: '/images/ends-and-odds/sub3_badges/metal_ball.png',
            badge37: '/images/ends-and-odds/sub3_badges/basketball_crest.png',
            badge38: '/images/ends-and-odds/sub3_badges/pinepoint_fire_dept_badge.png',
            badge39: '/images/ends-and-odds/sub3_badges/army_cadet_badge.png',
            badge40: '/images/ends-and-odds/sub3_badges/rescue_team.png',
            badge41: '/images/ends-and-odds/sub3_badges/pp_lioness_pin.png',
        },
        4: {
            text1: '/images/ends-and-odds/sub4/hats_title.png',
            bgVideo: '/chapter6/videos/6-5_hats_loop_hi/HLS.m3u8',
            bgVideoPoster: '/images/ends-and-odds/sub4/bgVideoPoster.jpg',
            soundfile1: '/audio/chapter6/5_z_basetrack.mp3',
            nextTitle: '/images/titles/Cosmos954Title.jpg'
        }

    },
    "cosmos-954": {
        0: {
            title: '/images/titles/Cosmos954Title.jpg',
            soundfile2: '/audio/chapter7/6_z_basetrack.mp3'
        },
        1: {
            text1: '/images/cosmos/sub1/Ch7Sub1Text1.png',
            text2: '/images/cosmos/sub1/Ch7Sub1Text2.png',
            background: '/images/cosmos/sub1/background.jpg',
            satellite: '/images/cosmos/sub1/satellite.png',
            explosion: '/images/cosmos/sub1/sat-crash.jpg',
            dust: '/images/cosmos/sub1/spinning-dust.png',
            soundfile1: '/audio/chapter7/6_b_satellite_buildup.mp3',
            explosionSound: '/audio/chapter7/6_b_satellite_crash.mp3'
        },
        2: {
            bgVideo: '/chapter7/videos/7-3_basketball_loop_hi/HLS.m3u8',
            bgVideoPoster: '/images/cosmos/sub2/basketball_video_poster.jpg',
            caption: '/images/cosmos/sub2/caption-7-2.png',
            soundfile1: '/audio/chapter7/6_c_sports_loop.mp3',
            soundfile3: '/audio/chapter7/6_vo_sports.mp3'
        },
        3: {
            background: '/images/cosmos/sub3/background.jpg',
            text1: '/images/cosmos/sub3/text-1.png',
            toothpick: '/images/cosmos/sub3/tooth-pick.png',
            hand1: '/images/cosmos/sub3/hand-sequence/1.png',
            hand2: '/images/cosmos/sub3/hand-sequence/2.png',
            hand3: '/images/cosmos/sub3/hand-sequence/3.png',
            hand4: '/images/cosmos/sub3/hand-sequence/4.png',
            hand5: '/images/cosmos/sub3/hand-sequence/5.png',
            hand6: '/images/cosmos/sub3/hand-sequence/6.png',
            hand7: '/images/cosmos/sub3/hand-sequence/7.png',
            hand8: '/images/cosmos/sub3/hand-sequence/8.png',
            soundfile1: '/audio/chapter7/6_d_fight_loop.mp3',
            soundfile3: '/audio/chapter7/6_vo_fight.mp3'
        },
        4: {
            text1: '/images/cosmos/sub4/text-1.png',
            text2: '/images/cosmos/sub4/text-2.png',
            caption: '/images/cosmos/sub4/caption-7-4.png',
            slide1: '/images/cosmos/sub4/slide/1.jpg',
            slide2: '/images/cosmos/sub4/slide/2.jpg',
            slide3: '/images/cosmos/sub4/slide/3.jpg',
            slide4: '/images/cosmos/sub4/slide/4.jpg',
            slide5: '/images/cosmos/sub4/slide/5.jpg',
            slide6: '/images/cosmos/sub4/slide/6.jpg',
            slide7: '/images/cosmos/sub4/slide/7.jpg',
            slide8: '/images/cosmos/sub4/slide/8.jpg',
            soundfile1: '/audio/chapter7/6_e_fire_loop.mp3',
            soundfile3: '/audio/chapter7/6_vo_fire.mp3'
        },
        5: {
            text1: '/images/cosmos/sub5/text.png',
            slide1: '/images/cosmos/sub5/slide/sat1.jpg',
            slide2: '/images/cosmos/sub5/slide/sat2.jpg',
            slide3: '/images/cosmos/sub5/slide/sat3.jpg',
            slide4: '/images/cosmos/sub5/slide/sat4.jpg',
            slide5: '/images/cosmos/sub5/slide/sat5.jpg',
            slide6: '/images/cosmos/sub5/slide/sat6.jpg',
            slide7: '/images/cosmos/sub5/slide/sat7.jpg',
            slide8: '/images/cosmos/sub5/slide/sat8.jpg',
            nextTitle: '/images/titles/HereToWorkTitle.jpg',
            soundfile2: '/audio/chapter7/6_z_basetrack.mp3',
            soundfile3: '/audio/chapter7/6_f_phone.mp3'
        }
    },
    "here-to-work": {
        0: {
            title: '/images/titles/HereToWorkTitle.jpg',
            soundfile1: '/audio/chapter8/7_a_loader.mp3'
        },
        1: {
            text1: '/images/here-to-work/sub1/text-1.png',
            slide1: '/images/here-to-work/sub1_slideshow/1.jpg',
            slide2: '/images/here-to-work/sub1_slideshow/2.jpg',
            slide3: '/images/here-to-work/sub1_slideshow/3.jpg',
            slide4: '/images/here-to-work/sub1_slideshow/4.jpg',
            slide5: '/images/here-to-work/sub1_slideshow/5.jpg',
            slide6: '/images/here-to-work/sub1_slideshow/6.jpg',
            slide7: '/images/here-to-work/sub1_slideshow/7.jpg',
            slide8: '/images/here-to-work/sub1_slideshow/8.jpg',
            slide9: '/images/here-to-work/sub1_slideshow/9.jpg',
            slide10: '/images/here-to-work/sub1_slideshow/10.jpg',
            soundfile1: '/audio/chapter8/7_b_pits_loop.mp3',
            soundfile2: '/audio/chapter8/7_z_basetrack.mp3'
        },
        2: {
            text1: '/images/here-to-work/sub2/ch8_sub2_copy1.png',
            text2: '/images/here-to-work/sub2/ch8_sub2_copy2.png',
            background: '/images/here-to-work/sub2/ch8_sub2_bg.jpg',
            soundfile1: '/audio/chapter8/7_c_pond_loop.mp3'
        },
        3: {
            text1: '/images/here-to-work/closeup_richard/text.png',
            background: '/images/here-to-work/closeup_richard/digger_bg.jpg',
            diggingSteam: '/images/here-to-work/closeup_richard/digging_steam.png',
            richardCloseup: '/images/here-to-work/closeup_richard/richard-closeup.png',
            soundfile1: '/audio/chapter8/7_d_mine_loop.mp3'
        },
        4: {
            text1: '/images/here-to-work/sub3/text.png',
            background: '/images/here-to-work/closeup_richard/digger_bg.jpg',
            diggingSteam: '/images/here-to-work/closeup_richard/digging_steam.png',
            digger1: '/images/here-to-work/sub3/digger_1.png',
            diggerSprite: '/images/here-to-work/sub3/digger_sprite2.png',
            diggerStand: '/images/here-to-work/sub3/digger_stand.png',
            diggingPile: '/images/here-to-work/sub3/digging_pile.png',
            diggingTrain: '/images/here-to-work/sub3/digging_train.png',
            diggingTrainpile: '/images/here-to-work/sub3/digging_trainpile.png',
            diggingRock: '/images/here-to-work/sub3/digging_rock.png',
            diggingLine: '/images/here-to-work/sub3/digging_line.jpg',
            diggingOverlay: '/images/here-to-work/sub3/digging_overlay.png',
            soundfile1: '/audio/chapter8/7_vo_richard.mp3',
            shovelSound1: '/audio/chapter8/7_shovel1.mp3',
            shovelSound2: '/audio/chapter8/7_shovel2.mp3',
            shovelSound3: '/audio/chapter8/7_shovel3.mp3',
            shovelSound4: '/audio/chapter8/7_shovel4.mp3',
            steamSound: '/audio/chapter8/7_steam.mp3'
        },
        5: {
            text1: '/images/here-to-work/sub4/ch8_sub4_copy1.png',
            bgVideo: '/chapter8/videos/8-5_rocks_hi/HLS.m3u8',
            bgVideoPoster: '/images/here-to-work/sub4/bgVideoPoster.jpg',
            soundfile2: '/audio/chapter8/7_z_basetrack.mp3',
            nextTitle: '/images/titles/ShelfLifeTitle.jpg',
        }
    },
    "shelf-life": {
        0: {
            title: '/images/titles/ShelfLifeTitle.jpg',
            soundfile2: '/audio/chapter10/8_z_basetrack.mp3'
        },
        1: {
            text1: '/images/shelf-life/sub1/Ch10Sub1Text1.png',
            cloud: '/images/shelf-life/sub1/cloud.png',
            bgVideo: '/chapter10/videos/birdanimation/HLS.m3u8',
            bgVideoPoster: '/images/shelf-life/sub1/bgVideoPoster.png',
        },
        2: {
            text1: '/images/shelf-life/sub2/Ch10Sub2Text2.png',
            caption: '/images/shelf-life/sub2/caption-10-2.png',
            bgVideo: '/chapter10/videos/9-3_wayne_brushing_hi/HLS.m3u8',
            bgVideoPoster: '/images/shelf-life/sub2/bgVideoPoster.jpg',
            divider: '/images/shelf-life/microfiche-divider.png',
            slide1: '/images/shelf-life/sub2/slides/1.jpg',
            slide2: '/images/shelf-life/sub2/slides/2.jpg',
            slide3: '/images/shelf-life/sub2/slides/3.jpg',
            slide4: '/images/shelf-life/sub2/slides/4.jpg',
            slide5: '/images/shelf-life/sub2/slides/5.jpg',
            slide6: '/images/shelf-life/sub2/slides/6.jpg',
            slide7: '/images/shelf-life/sub2/slides/7.jpg',
            slide8: '/images/shelf-life/sub2/slides/8.jpg',
            slide9: '/images/shelf-life/sub2/slides/9.jpg',
            slide10: '/images/shelf-life/sub2/slides/10.jpg',
            slide11: '/images/shelf-life/sub2/slides/11.jpg',
            slide12: '/images/shelf-life/sub2/slides/12.jpg',
            slide13: '/images/shelf-life/sub2/slides/13.jpg',
            slide14: '/images/shelf-life/sub2/slides/14.jpg',
            slide15: '/images/shelf-life/sub2/slides/15.jpg',
            soundfile1: '/audio/chapter10/8_vo_wayne.mp3'
        },
        3: {
            text1: '/images/shelf-life/sub3/Ch10Sub3Text1.png',
            text2: '/images/shelf-life/sub3/Ch10Sub3Text2.png',
            text3: '/images/shelf-life/sub3/Ch10Sub3Text3.png',
            bgVideo: '/chapter10/videos/9-4_shoveling_loop_hi/HLS.m3u8',
            bgVideoPoster: '/images/shelf-life/sub3/bgVideoPoster.jpg',
            divider: '/images/shelf-life/microfiche-divider.png',
            slide1: '/images/shelf-life/sub3/slides/2.jpg',
            slide2: '/images/shelf-life/sub3/slides/1.jpg',
            slide3: '/images/shelf-life/sub3/slides/3.jpg',
            slide4: '/images/shelf-life/sub3/slides/4.jpg',
            slide5: '/images/shelf-life/sub3/slides/5.jpg',
            slide6: '/images/shelf-life/sub3/slides/6.jpg',
            slide7: '/images/shelf-life/sub3/slides/7.jpg',
            slide8: '/images/shelf-life/sub3/slides/8.jpg',
            slide9: '/images/shelf-life/sub3/slides/9.jpg',
            slide10: '/images/shelf-life/sub3/slides/10.jpg',
            slide11: '/images/shelf-life/sub3/slides/11.jpg',
            slide12: '/images/shelf-life/sub3/slides/12.jpg',
            slide13: '/images/shelf-life/sub3/slides/13.jpg',
            slide14: '/images/shelf-life/sub3/slides/14.jpg',
            slide15: '/images/shelf-life/sub3/slides/15.jpg',
            slide16: '/images/shelf-life/sub3/slides/16.jpg',
            slide17: '/images/shelf-life/sub3/slides/17.jpg',
            slide18: '/images/shelf-life/sub3/slides/18.jpg',
            slide19: '/images/shelf-life/sub3/slides/19.jpg',
            slide20: '/images/shelf-life/sub3/slides/20.jpg',
            slide21: '/images/shelf-life/sub3/slides/21.jpg',
            slide22: '/images/shelf-life/sub3/slides/22.jpg',
            slide23: '/images/shelf-life/sub3/slides/23.jpg',
            slide24: '/images/shelf-life/sub3/slides/24.jpg',
            slide25: '/images/shelf-life/sub3/slides/25.jpg',
            slide26: '/images/shelf-life/sub3/slides/26.jpg',
            slide27: '/images/shelf-life/sub3/slides/27.jpg',
            slide28: '/images/shelf-life/sub3/slides/28.jpg',
            slide29: '/images/shelf-life/sub3/slides/29.jpg',
            slide30: '/images/shelf-life/sub3/slides/30.jpg',
            slide31: '/images/shelf-life/sub3/slides/31.jpg',
            slide32: '/images/shelf-life/sub3/slides/32.jpg',
            slide33: '/images/shelf-life/sub3/slides/33.jpg',
            slide34: '/images/shelf-life/sub3/slides/34.jpg',
            slide35: '/images/shelf-life/sub3/slides/35.jpg',
            slide36: '/images/shelf-life/sub3/slides/36.jpg',
            slide37: '/images/shelf-life/sub3/slides/37.jpg',
            slide38: '/images/shelf-life/sub3/slides/38.jpg',
            slide39: '/images/shelf-life/sub3/slides/39.jpg',
            slide40: '/images/shelf-life/sub3/slides/40.jpg',
            slide41: '/images/shelf-life/sub3/slides/41.jpg',
            slide42: '/images/shelf-life/sub3/slides/42.jpg',
            slide43: '/images/shelf-life/sub3/slides/43.jpg',
            slide44: '/images/shelf-life/sub3/slides/44.jpg',
            slide45: '/images/shelf-life/sub3/slides/45.jpg',
            slide46: '/images/shelf-life/sub3/slides/46.jpg',
            slide47: '/images/shelf-life/sub3/slides/47.jpg'
        },
        4: {
            text1: '/images/shelf-life/sub4/ch11_title1.png',
            credit: '/images/shelf-life/sub4/plaque_credit.png',
            background: '/images/shelf-life/sub4/ch11_bg.jpg',
        },
        5: {
            caption: '/images/shelf-life/sub5/caption-10-5.png',
            bgVideo: '/chapter11/videos/pinepoint_videowall_300kbps/HLS.m3u8',
            bgVideoPoster: '/images/shelf-life/sub5/bgVideoPoster.jpg',
            nextTitle: '/images/titles/WhatsWeirdTitle.jpg',
            demo1: '/chapter11/videos/10-3_demo1_lo/HLS.m3u8',
            demo2: '/chapter11/videos/10-3_demo2_lo/HLS.m3u8',
            demo3: '/chapter11/videos/10-3_demo3_lo/HLS.m3u8',
            demo4: '/chapter11/videos/10-3_demo4_lo/HLS.m3u8',
            demo5: '/chapter11/videos/10-3_demo6_lo/HLS.m3u8',
            demo6: '/chapter11/videos/10-3_demo7_lo/HLS.m3u8',
            demo7: '/chapter11/videos/10-3_demo8_lo/HLS.m3u8',
            demo8: '/chapter11/videos/10-3_demo9_lo/HLS.m3u8',
            demo9: '/chapter11/videos/10-3_demo10_lo/HLS.m3u8',
            demo10: '/chapter11/videos/10-3_demo11_lo/HLS.m3u8',
            demo11: '/chapter11/videos/10-3_demo12_lo/HLS.m3u8',
            demo12: '/chapter11/videos/10-3_demo13_lo/HLS.m3u8',
            demo1Poster: '/images/shelf-life/sub5/1.jpg',
            demo2Poster: '/images/shelf-life/sub5/2.jpg',
            demo3Poster: '/images/shelf-life/sub5/3.jpg',
            demo4Poster: '/images/shelf-life/sub5/4.jpg',
            demo5Poster: '/images/shelf-life/sub5/6.jpg',
            demo6Poster: '/images/shelf-life/sub5/7.jpg',
            demo7Poster: '/images/shelf-life/sub5/8.jpg',
            demo8Poster: '/images/shelf-life/sub5/9.jpg',
            demo9Poster: '/images/shelf-life/sub5/10.jpg',
            demo10Poster: '/images/shelf-life/sub5/11.jpg',
            demo11Poster: '/images/shelf-life/sub5/12.jpg',
            demo12Poster: '/images/shelf-life/sub5/13.jpg',
            soundfile2: '/audio/chapter10/8_z_basetrack.mp3'
        }

    },
    "whats-weird": {
        0: {
            title: '/images/titles/WhatsWeirdTitle.jpg',
            soundfile2: '/audio/chapter12/9_z_basetrack2.mp3'
        },
        1: {
            text1: '/images/whats-weird/sub1/title_panorama_slideshow.png',
            caption: '/images/whats-weird/sub1/caption-12-1.png',
            line: '/images/whats-weird/sub1/black-line.png',
            bgVideo: '/chapter13/videos/12-5_stepbystep_hi/HLS.m3u8',
            bgVideoPoster: '/images/whats-weird/sub1/videoPreview.jpg',
            soundfile1: '/audio/chapter12/9_vo_kim.mp3'
        },  
        2: {
            text1: '/images/whats-weird/sub2/text.png',
            background: '/images/whats-weird/sub2/projector-bg.png',
            slide1: '/images/whats-weird/sub2/sub2_assets/slide1.png',
            slide2: '/images/whats-weird/sub2/sub2_assets/slide2.png',
            slide3: '/images/whats-weird/sub2/sub2_assets/slide3.png',
            slide4: '/images/whats-weird/sub2/sub2_assets/slide4.png',
            slide5: '/images/whats-weird/sub2/sub2_assets/slide5.png',
            slide1Highlight: '/images/whats-weird/sub2/sub2_assets/slide1Highlight.png',
            slide2Highlight: '/images/whats-weird/sub2/sub2_assets/slide2Highlight.png',
            slide3Highlight: '/images/whats-weird/sub2/sub2_assets/slide3Highlight.png',
            slide4Highlight: '/images/whats-weird/sub2/sub2_assets/slide4Highlight.png',
            slide5Highlight: '/images/whats-weird/sub2/sub2_assets/slide5Highlight.png',
            soundfile1: '/audio/chapter12/9_c_projector_loop.mp3',
            slideSound1: '/audio/chapter12/9_ruffle1.mp3',
            slideSound2: '/audio/chapter12/9_ruffle2.mp3',
            slideSound3: '/audio/chapter12/9_ruffle3.mp3',
            slideSound4: '/audio/chapter12/9_ruffle4.mp3',
            slideSound5: '/audio/chapter12/9_ruffle5.mp3',
            slideSound6: '/audio/chapter12/9_ruffle6.mp3'
        },
        3: {
            text1: '/images/whats-weird/sub3/text.png',
            slide1: '/chapter12/videos/11-4_house1_hi/HLS.m3u8',
            slide1Poster: '/images/whats-weird/sub3/sub3_slideshow/11-4_house1_poster.jpg',
            slide2: '/images/whats-weird/sub3/sub3_slideshow/1.jpg',
            slide3: '/images/whats-weird/sub3/sub3_slideshow/3.jpg',
            slide4: '/images/whats-weird/sub3/sub3_slideshow/4.jpg',
            slide5: '/images/whats-weird/sub3/sub3_slideshow/5.jpg',
            slide6: '/chapter12/videos/11-4_house2_hi/HLS.m3u8',
            slide6Poster: '/images/whats-weird/sub3/sub3_slideshow/11-4_house2_poster.jpg',
            slide7: '/images/whats-weird/sub3/sub3_slideshow/6.jpg',
            slide8: '/images/whats-weird/sub3/sub3_slideshow/7.jpg',
            slide9: '/images/whats-weird/sub3/sub3_slideshow/8.jpg',
            slide10: '/images/whats-weird/sub3/sub3_slideshow/11.jpg',
            slide11: '/images/whats-weird/sub3/sub3_slideshow/12.jpg',
            slide12: '/images/whats-weird/sub3/sub3_slideshow/13.jpg',
            soundfile1: '/audio/chapter12/9_d_houses_loop.mp3'
        },
        4: {
            text1: '/images/whats-weird/sub4/left1.png',
            text2: '/images/whats-weird/sub4/right1.png',
            slide1: '/images/whats-weird/sub4/sub4_slideshow/11.jpg',
            slide2: '/images/whats-weird/sub4/sub4_slideshow/10.jpg',
            slide3: '/images/whats-weird/sub4/sub4_slideshow/3.jpg',
            slide4: '/images/whats-weird/sub4/sub4_slideshow/4.jpg',
            slide5: '/images/whats-weird/sub4/sub4_slideshow/5.jpg',
            slide6: '/images/whats-weird/sub4/sub4_slideshow/6.jpg',
            slide7: '/images/whats-weird/sub4/sub4_slideshow/8.jpg',
            slide8: '/images/whats-weird/sub4/sub4_slideshow/9.jpg',
            slide9: '/images/whats-weird/sub4/sub4_slideshow/1.jpg',
            soundfile1: '/audio/chapter12/9_e_basement_loop.mp3',
            soundfile2: '/audio/chapter12/9_z_basetrack2.mp3',
            nextTitle: '/images/titles/RemainsTitle.jpg',
        }
    },
    "remains": {
        0: {
            title: '/images/titles/RemainsTitle.jpg',
            soundfile3: '/audio/chapter13/10_a_loader.mp3',
        },
        1: {
            text1: '/images/remains/sub1/text1.png',
            bgVideo: '/chapter13/videos/12-1_tree_loop_hi/HLS.m3u8',
            bgVideoPoster: '/images/remains/sub1/bgVideoPoster.jpg',
            soundfile1: '/audio/chapter13/10_b_tree_loop.mp3',
            soundfile2: '/audio/chapter13/10_z_basetrack.mp3'
        },
        2: {
            text1: '/images/remains/sub2/text.png',
            caption: '/images/remains/sub2/caption-13-2.png',
            background: '/images/remains/sub2/background.jpg',
            building1: '/images/remains/sub2/building-1.png',
            building1Color: '/images/remains/sub2/building-1-color.png',
            building1Mask: '/images/remains/sub2/building-1-mask.png',
            building2: '/images/remains/sub2/building-2.png',
            building2Color: '/images/remains/sub2/building-2-color.png',
            building2Mask: '/images/remains/sub2/building-2-mask.png',
            building3: '/images/remains/sub2/building-3.png',
            building3Color: '/images/remains/sub2/building-3-color.png',
            building3Mask: '/images/remains/sub2/building-3-mask.png',
            building4: '/images/remains/sub2/building-4.png',
            building4base: '/images/remains/sub2/building-4_base.png',
            building4Color: '/images/remains/sub2/building-4-color.png',
            building4Mask: '/images/remains/sub2/building-4-mask.png',
            building5: '/images/remains/sub2/building-5.png',
            building5Color: '/images/remains/sub2/building-5-color.png',
            building5Mask: '/images/remains/sub2/building-5-mask.png',
            building6: '/images/remains/sub2/building-6.png',
            building6Color: '/images/remains/sub2/building-6-color.png',
            building6Mask: '/images/remains/sub2/building-6-mask.png',
            building7: '/images/remains/sub2/building-7.png',
            building7Color: '/images/remains/sub2/building-7-color.png',
            building7Mask: '/images/remains/sub2/building-7-mask.png',
            soundfile1: '/audio/chapter13/10_vo_lyle.mp3'
        },
        3: {
            text1: '/images/remains/sub3/text.png',
            bgVideo: '/chapter13/videos/12-7_richardwebsite_hi/HLS.m3u8',
            bgVideoPoster: '/images/remains/sub3/bgVideoPoster.jpg'
        },
        4: {
            text1: '/images/remains/sub4/text.png',
            bgVideo: '/chapter13/videos/12-8_richardcamera_hi/HLS.m3u8',
            bgVideoPoster: '/images/remains/sub4/bgVideoPoster.jpg'
        },
        5: {
            text1: '/images/remains/sub5/ch13_sub5Copy1.png',
            text2: '/images/remains/sub5/ch13_sub5Copy2.png',
            bgVideo: '/chapter13/videos/12-4_cutouts_hi/HLS.m3u8',
            bgVideoPoster: '/images/remains/sub5/bgVideoPoster.jpg',
            nextTitle: '/images/titles/OneForTheRoadTitle.jpg',
            soundfile1: '/audio/chapter13/10_c_stepbystep.mp3',
            soundfile2: '/audio/chapter13/10_z_basetrack.mp3'
        }
    },
    "one-for-the-road": {
        0: {
            title: '/images/titles/OneForTheRoadTitle.jpg',
            soundfile2: '/audio/chapter14/11_trooper_loop140.mp3'
        },
        1: {
            text1: '/images/one-for-the-road/sub1/ch14_title1.png',
            text2: '/images/one-for-the-road/sub1/ch14_copy2.png',
            text3: '/images/one-for-the-road/sub1/caption-14-1.png',
            bgVideo: '/chapter14/videos/13-1_fireworks_hi/HLS.m3u8',
            bgVideoPoster: '/images/one-for-the-road/sub1/bgVideoPoster.jpg',
            aboutBtn: '/images/global/about/about-btn.png',
            aboutBg: '/images/global/about/about-bg.jpg',
            aboutText1: '/images/global/about/about-text-1.png',
            aboutText2: '/images/global/about/about-text-2.png',
            michael: '/images/global/about/about-michael.png',
            paul: '/images/global/about/about-paul.png',
            nfb: '/images/global/about/about-nfb.png',
            close: '/images/global/about/about-close.png',
            soundfile1: '/audio/chapter14/11_because_tonight.mp3'
            
        }
    }
}


export function pageAssetData(num) {
    return assets[num];
}

function assetsData(chapter) {
    return assets[chapter];
}

export default assetsData;