import { /*SteppedEase, steps,*/ Quart, gsap } from "gsap"

//gsap.registerPlugin(CSSRulePlugin);

/*let images = [];
export function preload() {
    console.log(arguments.length);

    for (var i = 0; i < arguments.length; i++) {
        images[i] = new Image();
        images[i].src = arguments[i];
        console.log(
            "preload: " + images[i]
        )
    }
}*/

export function preloadAudio(audioSources, callback) {
    let sounds = [];

    let tryCallback = function () {
        //console.log("preloadAudio: tryCallback");
        //console.log(sounds);
        let allSoundsLoaded = (function () {
            for (let i = sounds.length; i--;) {
                if (!sounds[i].isLoaded) {
                    return false;
                }
            }
            return true;
        })();

        if (allSoundsLoaded) {
            callback();
        }
    }

    for (let i = audioSources.length; i--;) {
        let audioSrc = audioSources[i];
        let audio = new Audio(); //document.createElement('img');
        audio.preload = true;
        audio.src = audioSrc;
        audio.isLoaded = true;
        sounds.push(audio);
        tryCallback();
        audio.onerror = function() {
            console.error("Error occurred while loading " + audioSrc);
        };
    }
}

export function preloadImages(imageSources, callback, updateAfterEachImg = false) {

    let images = [];

    let tryCallback = function () {
        let allImagesLoaded = (function () {
            for (let i = images.length; i--;) {
                if (!images[i].isLoaded) {
                    return false;
                }
            }
            return true;
        })();

        if (allImagesLoaded) {
            callback();
        }
    };

    for (let i = imageSources.length; i--;) {
        let imageSrc = imageSources[i];
        let image = document.createElement('img');
        images.push(image);
        image.onload = function () {
            this.isLoaded = true;
            //console.log(this);
            //console.log("updateAfterEachImg: " + updateAfterEachImg);
            if(updateAfterEachImg){
                //update callback after each image load
                callback(imageSources.length - i, imageSources.length);
            } else {
                tryCallback();
            }
        };
        image.onerror = function() {
            console.error("Error occurred while loading " + imageSrc);
          };
        image.src = imageSrc;
    }
};

//animate a spritesheet
export function frameAnimation(target, width, height, col, row, maxFrames, speed, repeat) {

    //console.log('frameAnimation, ' + width + ", " + height);
    let frameWidth = width, frameHeight = height, numCols = col, numRows = row;
    //let tl = new TimelineMax({ repeat: repeat });
    let tl = gsap.timeline({ repeat: repeat });
    let totalRowCols = (numCols - 1);
    let totalLastRowCols = (numCols - 1) - (numCols * numRows - maxFrames);

    for (var i = 0; i < numRows; i++) {
        i === (numRows - 1) ? totalRowCols = totalLastRowCols : totalRowCols = (numCols - 1);
        //console.log('totalRowCols: ' + totalRowCols + ", totalLastRowCols: " + totalLastRowCols);
        tl.add(gsap.fromTo(target, speed, {
            backgroundPosition: '0 -' + (frameHeight * i) + 'px'
        }, {
            backgroundPosition: '-' + (frameWidth * totalRowCols) + 'px -' + (frameHeight * i) + 'px', ease:'steps('+totalRowCols+')' /*SteppedEase.config(totalRowCols)*/
        }));
        //console.log('row ' + i);
        //console.log('from 0 -' + (frameHeight * i) + 'px');
        //console.log('to -' + (frameWidth * totalRowCols) + 'px -' + (frameHeight * i) + 'px');
    };
}
//  frameAnimationReversed(this.watercolorRef, 644,   362,    6,   5,   29,        1,     0);
export function frameAnimationReversed(target, width, height, col, row, maxFrames, speed, repeat) {

    //console.log('frameAnimationReversed, ' + width + ", " + height);
    let frameWidth = width, frameHeight = height, numCols = col, numRows = row;
    //let tl = new TimelineMax({ repeat: repeat });
    let tl = gsap.timeline({ repeat: repeat });
    let totalRowCols = (numCols - 1);
    let totalLastRowCols = (numCols - 1) - (numCols * numRows - maxFrames);

    for (var i = numRows - 1; i >= 0; i--) {
        i === (numRows - 1) ? totalRowCols = totalLastRowCols : totalRowCols = (numCols - 1);
        //console.log(i + ' totalRowCols: ' + totalRowCols + ", totalLastRowCols: " + totalLastRowCols);

        tl.add(gsap.fromTo(target, speed, {
            backgroundPosition: '-' + (frameWidth * totalRowCols) + 'px -' + (frameHeight * i) + 'px'
        }, {
            backgroundPosition: '0 -' + (frameHeight * i) + 'px', ease: 'steps('+totalRowCols+')' /*SteppedEase.config(totalRowCols)*/
        }));
        //console.log('row ' + i);
        //console.log('from -' + (frameWidth * totalRowCols) + 'px -' + (frameHeight * i) + 'px');
        //console.log('to 0 -' + (frameHeight * i) + 'px');
    };
}

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

export function fmtMSS(s){
    return (s-(s%=60))/60+(9<s?':':':0')+s
}

export function shuffle (array) {

	var currentIndex = array.length;
	var temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;

};

//scale an element full screen
export function scaleFullscreen(el, availableWidth, availableHeight, contentWidth, contentHeight, centerHorizontally = true) {
    let x = 0;
    let s = availableWidth / (contentWidth-2);

    //console.log("availableWidth: " + availableWidth + ", availableHeight: " + availableHeight + ", contentWidth: " + contentWidth + ", contentHeight: " + contentHeight);
    //console.log("initial s: " + s);

    //if too short
    if (contentHeight * s < availableHeight) {
        s = availableHeight / (contentHeight-2);
        if(centerHorizontally){
            x = -(s * contentWidth - availableWidth) / 2;
        }
        //console.log("too short, get new s: " + s);
    }
    //console.log("scaleFullscreen: " + el);
    if (el) {
        gsap.to(el, 0, { left: x, scale: s });
    }
}

//scale an element full screen
export function scaleFullscreenBottomAligned(el, availableWidth, availableHeight, contentWidth, contentHeight, centerHorizontally = true) {
    let x = 0;
    let y = 0;
    let s = availableWidth / contentWidth;

    //console.log("scaleFullscreenBottomAligned: availableWidth: " + availableWidth + ", availableHeight: " + availableHeight + ", contentWidth: " + contentWidth + ", contentHeight: " + contentHeight);
    //console.log("initial s: " + s);
    
    //s = availableHeight / contentHeight;
    y = availableHeight - (s * contentHeight) + 1;

    //console.log("new s: " + s);
    //console.log("y: " + y);
    if(y > 0) y = 0;

    //if too short
    if (contentHeight * s < availableHeight) {
        s = availableHeight / contentHeight;
        //console.log("too short, get new s: " + s);
    }  
    if(centerHorizontally){
        x = -(s * contentWidth - availableWidth) / 2;
    }

    
    //console.log(el);
    //console.log(x + ", " + y + ", " + s);
    if (el) {
        gsap.to(el, 0, { left: x, top: y, scale: s });
    }
}


let animationDelay = 0;
export function resetAnimationDelay() {
    animationDelay = 0;
}

export function hideText(el, side) {
    animationDelay++;
    let textImg = el.querySelector("input[type='image']");
    //let textMargin = side === "left" ? parseInt(textImg.style.marginLeft, 10) : parseInt(textImg.style.marginRight, 10);
    //let textMargin = side === "left" ? textImg.style.marginLeft : textImg.style.marginRight;
    //if(!textMargin) textMargin = 0;
    let textMargin = 0;
    let delay = 0.1;
    let tweenTime = 0.3;
    let numPixelsToLeaveVisible = 50;
    let w = el.offsetWidth + textMargin;
    console.log('el.offsetWidth:' + el.offsetWidth);
    console.log("textMargin: " + textMargin);
    console.log(textImg);
    console.log(side + " text move: " + (el.offsetWidth - textMargin + numPixelsToLeaveVisible) + ", textMargin: " + textMargin + ", el.offsetWidth: " + el.offsetWidth);
    //let xTarget = side === "left" ? numPixelsToLeaveVisible : el.offsetWidth - textMargin + numPixelsToLeaveVisible / 5;
    let xTarget = side === "left" ? numPixelsToLeaveVisible : el.offsetWidth - textMargin - numPixelsToLeaveVisible;
    let subWidth = side === "left";
    let tr = Math.random() * 20 - 10;
    let ty = Math.random() * 50 - 25;
    let tx = xTarget; // + Math.random() * 30;

    console.log("side: " + side + ", xTarget: " + xTarget + ", tx: " + tx);

    if (subWidth) tx -= w;

    gsap.to(el, tweenTime, {
        x: Math.round(tx),
        y: Math.round(ty),
        rotation: tr,
        delay: animationDelay * (delay + Math.random() / 20),
        ease: Quart.easeInOut
    });
}

export function positionText(el, targetPosX, targetPosY, textPosition) {
    let l = targetPosX === "left" ? textPosition.left : "auto";
    let r = targetPosX === "right" ? textPosition.right : "auto";
    let t = targetPosY === "top" ? textPosition.top : "auto";
    let b = targetPosY === "bottom" ? textPosition.bottom : "auto";
    let tr = 0;
    gsap.set(el, {
        x: 0,
        y: 0,
        left: l,
        right: r,
        top: t,
        bottom: b,
        rotation: tr
    });
}

export function showText(el, targetPosX, targetPosY, textPosition, tweenTime = 0.3) {

    animationDelay++;
    let l = targetPosX === "left" ? textPosition.left : "auto";
    let r = targetPosX === "right" ? textPosition.right : "auto";
    let t = targetPosY === "top" ? textPosition.top : "auto";
    let b = targetPosY === "bottom" ? textPosition.bottom : "auto";
    let tr = 0;
    let delay = 0.1;

    //console.log('showText delay: ' + (animationDelay * (delay + Math.random() / 20)));

    gsap.to(el, tweenTime, {
        x: 0,
        y: 0,
        left: l,
        right: r,
        top: t,
        bottom: b,
        rotation: tr,
        delay: animationDelay * (delay + Math.random() / 20),
        ease: Quart.easeInOut
    });

}


// Helper function to get an element's exact position
export function getPosition(el) {
  var xPos = 0;
  var yPos = 0;
 
  while (el) {
    if (el.tagName === "BODY") {
      // deal with browser quirks with body/window/document and page scroll
      var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
      var yScroll = el.scrollTop || document.documentElement.scrollTop;
 
      xPos += (el.offsetLeft - xScroll + el.clientLeft);
      yPos += (el.offsetTop - yScroll + el.clientTop);
    } else {
      // for all other non-BODY elements
      xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
      yPos += (el.offsetTop - el.scrollTop + el.clientTop);
    }
 
    el = el.offsetParent;
  }
  return {
    x: xPos,
    y: yPos
  };
}

//Play HLS Video
export function loadVideo(videoRef, videoStream) {
    /* eslint-disable */
    if (Hls.isSupported()) {
        var video = videoRef;
        var hls = new Hls();
        // bind them together
        hls.attachMedia(video);
        // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
            //console.log("video and hls.js are now bound together !");
            hls.loadSource(videoStream);
            hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                //console.log("manifest loaded, found " + data.levels.length + " quality level");
            });
        });
    }
    /*eslint-enable */
}
