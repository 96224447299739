/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  console.log("We've started!");
}

// You can delete this file if you're not using it
exports.shouldUpdateScroll = () => {
  return false;
};

exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  console.log("Gatsby started to change location to", location.pathname)
  console.log("Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)

  let isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };

  //need this iPad test
  //since iOS 13 iPad no longer returns the user agent identifier
  function isIpad() {

    if ((/\b(iPad)\b/.test(navigator.userAgent)
      && /WebKit/.test(navigator.userAgent)
      && !window.MSStream)
      || (navigator.platform === 'MacIntel'
        && navigator.maxTouchPoints
        && navigator.maxTouchPoints === 5)
    ) {
      return true;
    }
  }

  if (!prevLocation) {
    let path = location.pathname;
    let pathArr = path.split("/");
    let newChapter = path.replace(/[0-9]/g, '');
    newChapter = newChapter.replace(/\\|\//g, '');
    if (newChapter === 'cosmos-') {
      newChapter = 'cosmos-954';
    }
    console.log(pathArr.length);
    console.log("REDIRECT TO " + newChapter);

    if( isMobile.any() || isIpad() ) {
      //if mobile or iPad redirect to mobile site
      window.location = "/mobile/" + newChapter;
      return;
    }

    //check if there is a subpage num at the end
    if (pathArr.length > 3) {
      //redirect to the base chapter with no sub-chapters
      window.location = "/" + newChapter;
    }
  }
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log('new pathname', location.pathname)
  console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}

exports.onRouteUpdateDelayed = () => {
  console.log("We can show loading indicator now")
}

require("./src/styles/styles.scss");

