import React, { useState, useEffect, useRef } from "react"
//import PropTypes from "prop-types"
import { useTransitionStore } from "gatsby-plugin-transitions"
//import { TransitionLink } from "gatsby-plugin-transitions";
//import * as easings from 'd3-ease'


const Menu = ({ globalState }) => {
    const [, dispatch] = useTransitionStore();
    const [chapterNum, setChapterNum] = useState(0);
    const [chapterName, setChapterName] = useState(globalState.currentChapter);
    const menuEl = useRef();
    const hitAreaEl = useRef();
    let closeTimer;
    const timerDelay = 5000;

    //class Menu extends React.Component {

    /**
     * props passed to PrevNext
     * 
     * globalState 
     * */

    const closeMenu = (event) => {
        console.log('closeMenu: ' + menuEl.current.classList.contains("open"));
        if(menuEl.current.classList.contains("open")){
            menuEl.current.classList.remove("open");
            hitAreaEl.current.classList.remove('open');
        }
    } 

    const handleMouseOut = (event) => {
        closeTimer = setTimeout(() => {
            //timed out, close menu
            menuEl.current.classList.remove("open");
            hitAreaEl.current.classList.remove('open');
        }, timerDelay);
    }
    
    const handleMouseOver = (event) => {
        console.log('handleMouseOver');
        clearTimeout(closeTimer);
    }

    //Directly link to the next page.
    const handleClick = (event) => {
        console.log(event.target.dataset.chaptername);
        console.log(event.target.dataset.chapternum);

        clearTimeout(closeTimer);
        
        let location = "/" + event.target.dataset.chaptername;
        let transformEnter;
        let transformLeave;
        let transitionDirection;

        //recalc enter and exit values
        //next
        if (chapterNum < event.target.dataset.chapternum) {
            transformEnter = "translate3d(100vw,0,0)";
            transformLeave = "translate3d(-100vw,0,0)";
            transitionDirection = "next"
        } else {
            //prev
            transformEnter = "translate3d(-100vw,0,0)";
            transformLeave = "translate3d(100vw,0,0)";
            transitionDirection = "prev"
        }

        //console.log("chapterName: " + chapterName + ", location: " + location + ", globalState.currentChapter: " + globalState.currentChapter);

        //only go to a different chapter than the current chapter
        if (menuEl.current.classList.contains("open") && globalState.currentChapter !== location) {
            //click to go to a chapter
            setChapterNum(event.target.dataset.chapternum);
            setChapterName(event.target.dataset.chaptername);

            //remove all sound tracks
            globalState.baseAudioRef.updateTrack(1, false, 0, false);
            globalState.baseAudioRef.updateTrack(2, false, 0, false);
            globalState.baseAudioRef.updateTrack(3, false, 0, false);
            globalState.baseAudioRef.updateTrack(4, false, 0, false);

            //let chapterNum = event.target.dataset.chapternum;
            dispatch({
                type: "NAVIGATE",
                to: location,
                enter: {
                    transform: transformEnter,
                    config: { delay: 100, mass: 1, tension: 170, friction: 26, velocity: 0 },
                    //config: { mass: 1, tension: 120, friction: 14, clamp: true, duration: globalState.transitionDuration, easing: easings.easeQuadInOut },
                    onRest: () => {
                        globalState.transitionRef.classList.remove("is-transitioning", transitionDirection);
                    }
                },
                usual: {
                    transform: "translate3d(0vw,0,0)"
                },
                leave: {
                    transform: transformLeave,
                    config: { delay: 100, mass: 1, tension: 170, friction: 26, velocity: 0 },
                    //config: { mass: 1, tension: 120, friction: 14, clamp: true, duration: globalState.transitionDuration + 35, easing: easings.easeQuadInOut },
                    onStart: () => {
                        globalState.transitionRef.classList.add("is-transitioning", transitionDirection);
                        if (globalState.isTransitioning) {
                            //isTransitioning();
                        }
                    }
                },
                mode: "immediate"
            });
            menuEl.current.classList.remove("open");
            hitAreaEl.current.classList.remove('open');
        } else {
            //click to open the navigation
            menuEl.current.classList.add("open");
            hitAreaEl.current.classList.add('open');

            //autoclose after 3 seconds
        }


    }

    useEffect(() => {

        console.log("******** menu component updated " + chapterNum + ", " + chapterName);
        console.log(menuEl.current.getElementsByTagName("button"));
        
        for (const btn of menuEl.current.getElementsByTagName("button")) {
            if (btn.dataset.chaptername === globalState.currentChapter) {
                btn.parentElement.classList.add('active');
            } else {
                btn.parentElement.classList.remove('active');
            }
        }
    })

    

    //render() {
    return (
        <footer id="footer">
            <button className="menuHitArea" ref={hitAreaEl} onClick={closeMenu}></button>
            <ul className="menu" ref={menuEl}>
                <li><button data-chapternum={0} data-chaptername="intro" className="introBtn" onClick={handleClick} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onFocus={handleMouseOver} onBlur={handleMouseOut} alt="Intro"></button></li>
                <li><button data-chapternum={1} data-chaptername="town" className="townBtn" onClick={handleClick} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onFocus={handleMouseOver} onBlur={handleMouseOut} alt="Town"></button></li>
                <li><button data-chapternum={2} data-chaptername="pinepointers" className="pinepointersBtn" onClick={handleClick} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onFocus={handleMouseOver} onBlur={handleMouseOut} alt="Pinepointers"></button></li>
                <li><button data-chapternum={3} data-chaptername="ends-and-odds" className="endsandoddsBtn" onClick={handleClick} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onFocus={handleMouseOver} onBlur={handleMouseOut} alt="Ends and Odds"></button></li>
                <li><button data-chapternum={4} data-chaptername="cosmos-954" className="cosmos954Btn" onClick={handleClick} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onFocus={handleMouseOver} onBlur={handleMouseOut} alt="Cosmos 954"></button></li>
                <li><button data-chapternum={5} data-chaptername="here-to-work" className="heretoworkBtn" onClick={handleClick} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onFocus={handleMouseOver} onBlur={handleMouseOut} alt="Here to Work"></button></li>
                <li><button data-chapternum={6} data-chaptername="shelf-life" className="shelflifeBtn" onClick={handleClick} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onFocus={handleMouseOver} onBlur={handleMouseOut} alt="Shelf Life"></button></li>
                <li><button data-chapternum={7} data-chaptername="whats-weird" className="whatsweirdBtn" onClick={handleClick} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onFocus={handleMouseOver} onBlur={handleMouseOut} alt="What's Weird"></button></li>
                <li><button data-chapternum={8} data-chaptername="remains" className="remainsBtn" onClick={handleClick} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onFocus={handleMouseOver} onBlur={handleMouseOut} alt="Remains"></button></li>
                <li><button data-chapternum={9} data-chaptername="one-for-the-road" className="onefortheroadBtn" onClick={handleClick} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onFocus={handleMouseOver} onBlur={handleMouseOut} alt="One for the Road"></button></li>
            </ul>
        </footer >
    );
}

export default Menu



