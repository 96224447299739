import React from "react"
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions"
import Menu from "../components/navigation/menu"
import BaseAudioController from "../components/media/base-audio-controller"
import { preloadImages } from "../helpers"
import SEO from "../components/seo"

//Assets daata
import assetsData from "../data/assets"

let currentLocation;

class Layout extends React.Component {

  constructor(props) {
    super(props)
    this.state = { 
      isActivated: false,
      updateIsActivated: null,
      menuIsVisible: false,
      updateMenuIsVisible: null,
      hideHeader: null,
      cdnUrl: 'https://pinepoint.nfb.ca',
      //cdnUrl: 'https://s3.amazonaws.com/pinepoint.nfb.ca',
      videoUrl: 'https://pinepoint.nfb.ca/streams',
      //videoUrl: 'https://s3.amazonaws.com/pinepoint.nfb.ca/streams',
      silence: "/audio/global/one-second-of-silence.mp3",
      transitionRef: null,
      updateTransitionDirection: null,
      transitionDirection: null,
      transitionDuration: 1000,
      baseAudioRef: null,
      //updateAssetsData: null,
      assets: assetsData("intro"),
      currentChapter: null,
      showPrevNextDelay: 2,
      screensize: {
        defaultWidth: 1280,
        defaultHeight: 760,
        currentWidth: 1280,
        currentHeight: 760
      },
      textPosition: {
        leftTop: {
          left: "6vw",
          top: "10vh"
        },
        rightTop: {
          right: "2vw",
          top: "9vh"
        },
        rightBottom: {
          right: "2vw",
          bottom: "4vh"
        },
        leftBottom: {
          left: "6vw",
          bottom: "4vh"
        }
      }
     }
  }
  


  //update globalState for activated.
  updateIsActivated = (bool) => {
    this.setState({ isActivated: bool });
    if(this.state.currentChapter !== "intro"){
      this.setState({menuIsVisible: true});
    }
  }

  //update globalState for activated.
  updateMenuIsVisible = () => {
    this.setState({menuIsVisible: true});
  }

  //update globalState for transtionDirection.
  updateTransitionDirection = (dir) => {
    this.setState({ transitionDirection: dir });
  }

  //updateAssetsData = (data) => {
    //this.setState({ assets: data });
  //}

  updateNextLocation = () => {
    console.log('updateNextLocation() called');
  }

  muteSound = () => {
    console.log("MUTE SOUND");
  }

  triggerHeaderLoaded = (e) => {
    console.log('! ! ! ! header downloaded');
  }




  componentDidMount = () => {
    //Add resize listener
    this.updateDimensions();

    console.log("Component Mounted");
    
    /* eslint-disable */

    //Initiate default State
    this.setState({ transitionRef: this.refs.viewsWrapperRef });
    this.setState({ baseAudioRef: this.refs.baseAudioRef });
    //this.setState({ updateAssetsData: this.updateAssetsData });
    this.setState({ updateIsActivated: this.updateIsActivated });
    this.setState({ updateMenuIsVisible: this.updateMenuIsVisible });
    this.setState({ updateTransitionDirection: this.updateTransitionDirection });
    this.setState({ hideHeader: this.hideHeader });


    let parent = this;
    let path = this.props.location.pathname;

    //NFB Header and Tracking Code
    $(document).ready(function () {
      var indexOptions = {
        currentProjectId: 64,
        base_url: {
          //fr: "http://pinepoint.onf.ca/",
          en: "http://pinepoint.nfb.ca/"
        },
        inverted: true,
        header: {
          hideOnActivity: false,
          hideWithButton: true
        }
      }
      
      $(document).on( "ifw_headerLoaded", function() {
        console.log('Header is loaded');
      });


      //Set up listener for mute from header
      $(document).on( "ifw_openOverlay ", function() {
        console.log('Open overlay');
      });
      
      $(document).on( "ifw_closeOverlay", function() {
        console.log('Close overlay');
      });
      
      $(document).on( "ifw_muteSound", function() {
        console.log('Mute sound');
        console.log(parent);
        parent.refs.baseAudioRef.muteAudio(true);
      });
      
      $(document).on( "ifw_unmuteSound", function() {
        console.log('Unmute sound');
        parent.refs.baseAudioRef.muteAudio(false);
      });
      
      load_framework.init(indexOptions); /* eslint-disable-line */
      
      
    });
    /* eslint-enable */


    //let currentChapter = location.pathname.replace(/[0-9]|[\/\\]/g, '');
    //this.setState({ currentChapter: currentChapter});

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.updateDimensions)
    }

    //preload global image assets
    preloadImages([
      this.state.cdnUrl + '/images/global/prev.png', 
      this.state.cdnUrl + '/images/global/next.png', 
      this.state.cdnUrl + '/images/global/sub-chapter-filler.png',
      this.state.cdnUrl + '/images/global/navigation/intro-bg-active.png',
      this.state.cdnUrl + '/images/global/navigation/town-bg-over.png',
      this.state.cdnUrl + '/images/global/navigation/pinepointers-bg-over.png',
      this.state.cdnUrl + '/images/global/navigation/ends-and-odds-bg-over.png',
      this.state.cdnUrl + '/images/global/navigation/cosmos-bg-over.png',
      this.state.cdnUrl + '/images/global/navigation/here-to-work-bg-over.png',
      this.state.cdnUrl + '/images/global/navigation/shelf-life-bg-over.png',
      this.state.cdnUrl + '/images/global/navigation/whats-weird-bg-over.png',
      this.state.cdnUrl + '/images/global/navigation/remains-bg-over.png',
      this.state.cdnUrl + '/images/global/navigation/one-forthe-road-bg-over.png'
    ], this.globalImagesLoaded);

  }

  hideHeader = () => {
    header.hide(); /* eslint-disable-line */
  }

  componentDidUpdate = () => {
    console.log("******** layout component updated ");
    
    let path = this.props.location.pathname;
    console.log('path: ' + path);
    let newChapter = path.replace(/[0-9]/g, '');
    newChapter = newChapter.replace(/\\|\//g, '');
    console.log('newChapter: ' + newChapter);
    
    //fix for Cosmos-954
    if(newChapter === 'cosmos-') {
      newChapter = 'cosmos-954';
    }

    //Site initial load with no chapter
    if (!newChapter) {
      newChapter = "intro";
    }

    console.log(this.state.currentChapter + ", " + newChapter);

    if (this.state.currentChapter !== newChapter) {
      //update state
      this.setState({ currentChapter: newChapter });
      //update assets data for this chapter
      this.setState({ assets: assetsData(newChapter) });

    }

    /* eslint-disable */
    if(path != currentLocation){
      try {
        
        console.log("gaTrack: " + path);
        google_analytics.gaTrack({
          language: "en",
          name: "Pinepoint",
          ua_code: "UA-57121480-1",  
          pageview: path
        });
        
      } catch (e) {
        console.warn("gaTrack not yet loaded from NFB Header")
      }
    }
    /* eslint-enable */

    //keep track of current location
    currentLocation = path;

  }




  updateDimensions = () => {
    console.log('LAYOUT: updateDimensions');
    const screensize = { ...this.state.screensize };
    screensize[`currentWidth`] = window.innerWidth;
    screensize[`currentHeight`] = window.innerHeight;
    this.setState({ screensize });
    //this.state.screensize.currentWidth = window.innerWidth;
    //this.state.screensize.currentHeight = window.innerHeight; 

  }

  globalImagesLoaded = () => {
    console.log("Global image assets loaded");
  }

  render() {

    const { location, children } = this.props;
    
    location.state = this.state;
    
    return (
      <TransitionProvider
        location={location}
        mode="immediate"
        enter={{
          transform: "translate3d(0vw,0,0)",
          opacity: 0,
          config: {
            delay: 100,
            mass: 1,
            tension: 210,
            friction: 20,
            duration: 500,
            clamp: true
          },
          onRest: () => {

          }
        }}
        usual={{
          transform: "translate3d(0vw,0,0)",
          opacity: 1,
        }}
        leave={{
          transform: "translate3d(0vw,0,0)",
          opacity: 0,
          config: {
            delay: 100,
            mass: 1,
            tension: 210,
            friction: 20,
            duration: 500,
            clamp: true
          },
          onStart: () => {

          }
        }}>
        <SEO title="NFB - Welcome to Pine Point" />
        <BaseAudioController ref="baseAudioRef" audioData={this.state.audio} />

        {this.state.menuIsVisible ?
         <Menu globalState={this.state} callback={ this.handleMenuClick } /> : ''
        }

        <div ref="viewsWrapperRef" className={"views-wrapper"}>
          <TransitionViews>
            {children}
          </TransitionViews>
        </div>
        
      </TransitionProvider>
    );
  }

};


export default Layout;