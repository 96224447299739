

import React from "react"
//import { gsap } from "gsap"
import Sound from 'react-sound';
//Sound.soundManager.setup({ ignoreMobileRestrictions: true }); /*eslint-disable-line*/


//import SoundManager from 'soundmanager2'
//SoundManager.soundManager.setup({ ignoreMobileRestrictions: true })


class BaseAudioController extends React.Component {

    /*
    constructor(props) {
         super(props);
    }
    */
    state = {
        isMuted: false,
        baseTrack1: {
            file: false,
            volume: 0
        },
        baseTrack2: {
            file: false,
            volume: 0
        },
        baseTrack3: {
            file: false,
            volume: 0
        },
        baseTrack4: {
            file: false,
            volume: 0
        }
    }

    componentDidMount() {

        //import SoundManager from 'soundmanager2'
        //let sm2 = require('soundmanager2');
        console.log("base audio controller");
        //console.log(soundManager); /*eslint-disable-line*/
        //console.log(sm2);
        
        //soundManager.setup({ ignoreMobileRestrictions: true })
    }
    


    updateTrack = (trackNum, trackFile, vol=100, fadeInOut = true) => {

        console.log("updateTrack " + trackNum + ", trackFile: " + trackFile);
        //
        const currentBaseTrack = this.state["baseTrack"+trackNum];
        const baseTrack = { ...this.state["baseTrack"+trackNum] };
        baseTrack[`file`] = trackFile; 
        baseTrack[`volume`] = vol;

        console.log(currentBaseTrack.file);

        if(!trackFile && currentBaseTrack.file){
            //No new trackFile, if already exists fade out and set trackFile to false
            if(fadeInOut){
                this.fadeOut(trackNum, false);
            } else {
                this.setState({["baseTrack"+trackNum]: baseTrack});
            }
            return;
        }
        
        if(!currentBaseTrack.file){
            //New TrackFile, and previous track did not exist
            this.setState({["baseTrack"+trackNum]: baseTrack});
            
        } else if (currentBaseTrack.file === trackFile) {
            console.log("Same file no update necessary");
            //Same file, no update necessary
            return;
            
        } else {
            //New TrackFile, and previous track did exist
            //Fade out old and play new.
            const baseTrack = { ...this.state["baseTrack"+trackNum] };
            baseTrack[`file`] = trackFile;
            baseTrack[`volume`] = vol;
            fadeInOut ? this.fadeOut(trackNum, trackFile) : this.setState({["baseTrack"+trackNum]: baseTrack});

        }
    }

    fadeOut = (trackNum, trackFile) => {
        console.log("fadeOut " + trackNum + ", trackFile: " + trackFile);
        //let trackRef = this.refs["baseTrack"+trackNum+"Ref"];

        //console.log(trackRef.playStatus);
        
        const currentBaseTrack = this.state["baseTrack"+trackNum];
        const baseTrack = { ...this.state["baseTrack"+trackNum] };
        let vol = currentBaseTrack.volume;
        const fadeOutInterval = setInterval(() => {
            vol -= 5;
            baseTrack[`volume`] = vol;
            this.setState({["baseTrack"+trackNum]: baseTrack});
            //console.log(vol);
            if(vol <= 0){
                clearInterval(fadeOutInterval);
                baseTrack[`file`] = trackFile;
                trackFile ? vol = 100 : vol = 0;
                baseTrack[`volume`] = vol;
                this.setState({["baseTrack"+trackNum]: baseTrack});
            }
        }, 50);
    }


    updateVolume = (trackNum, newVolume, callback) => {
        const currentBaseTrack = this.state["baseTrack"+trackNum];
        const baseTrack = { ...this.state["baseTrack"+trackNum] };
        let vol = currentBaseTrack.volume;
        if(vol === newVolume){
            //volume already there
            return;
        }
        const fadeOutInterval = setInterval(() => {
            newVolume < vol ? vol -= 1 : vol += 1;
            baseTrack[`volume`] = vol;
            this.setState({["baseTrack"+trackNum]: baseTrack});
            //console.log("updateVolume: " + vol);
            if(vol === newVolume){
                clearInterval(fadeOutInterval);
                //baseTrack[`volume`] = vol;
                //this.setState({["baseTrack"+trackNum]: baseTrack});
            }
        }, 10);

    }

    
    //for tracks 3 and 4 which do not loop
    handleTrackFinishedPlaying = (trackNum) => {
        console.log("handleTrackFinishedPlaying: " + trackNum);

        //unload track to prevent looping
        const baseTrack = { ...this.state["baseTrack"+trackNum] };
        baseTrack[`file`] = false;
        baseTrack[`volume`] = 0;
        this.setState({["baseTrack"+trackNum]: baseTrack});
    }

    muteAudio = (bool) => {
        this.setState({isMuted: bool});
    }

    isMuted = () => {
        return this.state.isMuted;
    }


    handleStateChange(state, prevState) {
        //console.log('base audio controller state changed');
    }

    render() {
        //Allow more than one audio track to play at a time

        return (
            <>
                {this.state.baseTrack1.file  && !this.state.isMuted ?
                    <div className="base-track-1">
                    <Sound
                        ref="baseTrack1Ref"
                        url={this.state.baseTrack1.file}
                        playStatus={Sound.status.PLAYING}
                        autoLoad={true}
                        loop={true}
                        volume={this.state.baseTrack1.volume}
                        
                    //onLoading={this.handleSongLoading}
                    //onPlaying={this.handleSongPlaying}
                    //onFinishedPlaying={this.handleSongFinishedPlaying}
                    />
                    </div>
                    :
                    ""
                }
                {this.state.baseTrack2.file && !this.state.isMuted ?
                    <div className="base-track-2">
                    <Sound
                        ref="baseTrack2Ref"
                        url={this.state.baseTrack2.file}
                        playStatus={Sound.status.PLAYING}
                        autoLoad={true}
                        loop={true}
                        volume={this.state.baseTrack2.volume}
                        
                    //onLoading={this.handleSongLoading}
                    //onPlaying={this.handleSongPlaying}
                    //onFinishedPlaying={this.handleSongFinishedPlaying}
                    />
                    </div>
                    :
                    ""
                }
                {/* Use for Non-looping tracks */}
                {this.state.baseTrack3.file && !this.state.isMuted ?
                    <div className="base-track-3">
                    <Sound
                        ref="baseTrack3Ref"
                        url={this.state.baseTrack3.file}
                        playStatus={Sound.status.PLAYING}
                        autoLoad={true}
                        loop={false}
                        volume={this.state.baseTrack3.volume}
                        
                        onFinishedPlaying={()=>{
                            this.handleTrackFinishedPlaying(3);
                        }}
                    //onLoading={this.handleSongLoading}
                    //onPlaying={this.handleSongPlaying}
                    //onFinishedPlaying={this.handleSongFinishedPlaying}
                    />
                    </div>
                    :
                    ""
                }
                {/* Use for Non-looping tracks */}
                {this.state.baseTrack4.file && !this.state.isMuted ?
                    <div className="base-track-4">
                    <Sound
                        ref="baseTrack4Ref"
                        url={this.state.baseTrack4.file}
                        playStatus={Sound.status.PLAYING}
                        autoLoad={true}
                        loop={false}
                        volume={this.state.baseTrack4.volume}
                        
                        onFinishedPlaying={()=>{
                            this.handleTrackFinishedPlaying(4);
                        }}

                    //onLoading={this.handleSongLoading}
                    //onPlaying={this.handleSongPlaying}
                    //onFinishedPlaying={this.handleSongFinishedPlaying}
                    />
                    </div>
                    :
                    ""
                }
            </>
        )
    }

}



export default BaseAudioController;
